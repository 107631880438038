export default class Campaign {
  constructor() {
    const heroForm = document.querySelector('.hero-form form'),
    footerForm = document.querySelector('.cta-form form');

    if (heroForm) {
      this.heroForm = heroForm;
      this.initHeroFormValidation(heroForm);
    }
    if (footerForm) {
      this.initFooterFormValidation(footerForm);
    }
  }
  initHeroFormValidation(heroForm) {
    if (heroForm) {
      $(function(e) {
        jQuery.validator.addMethod('phone_rule', function(value, element) {
          if (/^[-+.0-9\(\)\s]{4,25}$/.test(value)) {
            return true;
          };
        }, 'Please enter a valid phone number.'); 
        
        jQuery.validator.addMethod('email_rule', function(value, element) {
          if (/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(value)) {
            return true;
          };
        }, 'Please enter a valid email address.');
        
        $(heroForm).validate({
          rules: {
            'formfields[first_name]': {
              required: true
            },
            'formfields[last_name]': {
              required: true
            },
            'formfields[email]': {
              required: true,
              email_rule: true
            },
            'formfields[phone]': {
              required: true,
              phone_rule: true
            },
            'formfields[company_size]': {
              required: true,
            },
          },
          messages: {
            'formfields[first_name]': "Please specify your first name",
            'formfields[last_name]': "Please specify your last name",
            'formfields[email]': "Please enter a valid email address",
            'formfields[phone]': "Please enter a valid phone number",
            'formfields[company_size]': "Please select a company size",
          },
        });
      });
    }
  }
  initFooterFormValidation(footerForm) {
    if (footerForm) {
      $(function() {
        jQuery.validator.addMethod('phone_rule', function(value, element) {
          if (/^[-+.0-9\(\)\s]{4,25}$/.test(value)) {
            return true;
          };
        }, 'Please enter a valid phone number.'); 
        
        jQuery.validator.addMethod('email_rule', function(value, element) {
          if (/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(value)) {
            return true;
          };
        }, 'Please enter a valid email address.');
        
        $(footerForm).validate({
          rules: {
            'formfields[first_name]': {
              required: true
            },
            'formfields[last_name]': {
              required: true
            },
            'formfields[company]': {
              required: true
            },
            'formfields[email]': {
              required: true,
              email_rule: true
            },
            'formfields[phone]': {
              required: true,
              phone_rule: true
            },
            'formfields[company_size]': {
              required: true,
            },
          },
          messages: {
            'formfields[first_name]': "Please specify your first name",
            'formfields[last_name]': "Please specify your last name",
            'formfields[company]': "Please specify your company name",
            'formfields[email]': "Please enter a valid email address",
            'formfields[phone]': "Please enter a valid phone number",
            'formfields[company_size]': "Please select a company size",
          },
        });
      });
    }
  }
}