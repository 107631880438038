export default class Nav {
  constructor() {
    const hamburgerMenu = document.querySelectorAll('.hamburger-menu'),
    dropdowns = document.querySelectorAll('.dropdown-toggle');

    if (document.querySelector('.fixed-top')) {
      this.initScroll();
    }
    if (hamburgerMenu.length) {
      this.hamburgerMenu = hamburgerMenu;
      this.initSideNavState();
    }
    if (dropdowns.length) {
      this.dropdowns = dropdowns;
      this.hideDropdownOnClick();
    }
  }

  initNavActiveState() {
    if (location.pathname != '/') {
      $('.main-nav li a[href^="/' + location.pathname.split("/")[1] + '"]').addClass('active');
    }
  }

  initScroll() {
    /* =========================================
      # Hide / show the master navigation menu
    ============================================ */
    // console.log('Window Height is: ' + $(window).height());
    // console.log('Document Height is: ' + $(document).height());
    
    var previousScroll = 0;
    
    $(window).scroll(function () {
      
      var currentScroll = $(this).scrollTop();
      const mobileSearch = document.getElementById('search-mobile'),
      searchMain = document.querySelector('.search-main');
      
      /* 
      If the current scroll position is greater than 0 (the top) AND the current scroll position is less than the document height minus the window height (the bottom) run the navigation if/else statement.
      */
      if (currentScroll < 100) {
        // mobileSearch.classList.remove('scrolling');
        searchMain.classList.add('d-none');

        showTopNav();
      } else if (currentScroll > 0 && currentScroll < $(document).height() - $(window).height()) {

        // if ($(document).width() >= '576' && $(document).width() <= '767.98') {
        //   mobileSearch.classList.add('scrolling');
        // }

        /*
        If the current scroll is greater than the previous scroll (i.e we're scrolling down the page), hide the nav.
        */
        if (currentScroll > previousScroll) {
          hideNav();
          /*
          Else we are scrolling up (i.e the previous scroll is greater than the current scroll), so show the nav.
          */
        } else {
          showNav();
        }
        /* 
        Set the previous scroll value equal to the current scroll.
        */
        previousScroll = currentScroll;
      }
      
    });
    
    function hideNav() {
      $("[data-nav-status='toggle']").removeClass('is-visible').addClass('is-hidden');
    }
    function showNav() {
      $("[data-nav-status='toggle']").removeClass('is-hidden').addClass('is-visible').addClass('scrolling');
    }
    function showTopNav() {
      $("[data-nav-status='toggle']").removeClass('is-hidden').addClass('is-visible').removeClass('scrolling');
    }
  }

  initSideNavState() {
    const mobileOverlay = document.querySelector('.mobile-overlay'),
    body = document.querySelector('body'),
    mobileNav = document.querySelector('.mobile-nav'),
    mobileCloseNav = document.querySelector('.close-mobile-nav');

    if (this.hamburgerMenu) {
      this.hamburgerMenu.forEach (burger => {
        burger.addEventListener('click', function() {
          mobileNav.classList.add('active');
          mobileCloseNav.classList.add('open');
          mobileOverlay.classList.add('open');

          if (mobileOverlay.classList.contains('open')) {
            body.classList.add('overflow-hidden');
          }
        });
      });
    }
    
    if (mobileCloseNav) {
      mobileCloseNav.addEventListener('click', function() {
        mobileNav.classList.remove('active');
        mobileCloseNav.classList.remove('open');
        mobileOverlay.classList.remove('open');
        body.classList.remove('overflow-hidden');
      });
    }
  }

  hideDropdownOnClick() {
    this.dropdowns.forEach(dropdown => {
      dropdown.addEventListener('click', function() {
        const dropdownMenu = dropdown.nextElementSibling;
        dropdownMenu.classList.add('d-none');
      });
    });
  }
}