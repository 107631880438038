export default class JoinTheList {
  constructor() {
    const footerFormInput = $('footer form #email');
    if (footerFormInput) {
      this.footerValidation();
    }
  }
  footerValidation() {
    // Remove cms default validation
    const footerFormInput = $('footer form #email');

    footerFormInput.attr("required", "");
    footerFormInput.attr("data-error", "Please enter a valid email address");
    footerFormInput.removeClass('validate[required]');
    $('footer form button:submit').click(function() {
      footerFormInput.removeClass('validate[required]');
    });

    // Add new validation
    $('footer form').attr( { id: 'join-list-form', novalidate: "novalidate" } );
  
    jQuery.validator.addMethod('email_rule', function(value, element) {
      if (/^([a-zA-Z0-9_\-\.]+)\+?([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(value)) {
        return true;
      };
    });
    $('#join-list-form').validate({
      'formfields[email]': {
        required: true,
        email_rule: true
      },
      messages: {
        'formfields[email]': "Please enter a valid email address.",
      }
    });

    // Check url after submit/reload to keep view on form
    if (window.location.href.indexOf('?module_dm_id=40') > 0) {
      const joinForm = document.querySelector('footer');
      if (joinForm) {
        setTimeout(function() {
          joinForm.scrollIntoView();
        }, 2);
      }
    }
  }
}