'use strict';

// Packages
import 'jquery';
import '@popperjs/core';
import 'bootstrap';
import 'slick-carousel';
import 'codemirror-minified';
import './components/codemirror-init';
import validate from 'jquery-validation';

import Banner from './components/privacy-banner';
import CareersValidation from './components/validation/careers';
import Codemirror from './components/codemirror';
import LazyLoading from './components/lazyload';
import Nav from './components/nav';
import Scroll from './components/scroll';
import Slider from './components/slider';
import Tracking from './components/tracking';
import Utils from './utils/utils';
import JoinTheList from './components/validation/join-the-list';
import Campaign from './components/validation/campaign-lps';
import Form from './components/form';
import Modals from './components/modal';
import CustomerIO from './components/customer-io';
import Login from './components/login';

$(function() {
  new Banner();
  new CareersValidation();
  new Codemirror();
  new LazyLoading();
  new Nav();
  new Scroll();
  new Slider();
  new Tracking();
  new Utils();
  new JoinTheList();
  new Campaign();
  new Form();
  new Modals();
  new CustomerIO();
  new Login();
});