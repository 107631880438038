import * as bootstrap from 'bootstrap';

export default class Modals {
  
  constructor() {
    const getStartedModals = document.querySelectorAll('.get-started'),
    buyNowModals = document.querySelectorAll('.buy-now');

    if (getStartedModals.length) {
      getStartedModals.forEach(getStartedModal => {
        this.getStartedModal = getStartedModal;
        this.initGetStartedModal();
      });
    }
    if (buyNowModals.length) {
      buyNowModals.forEach(buyNowModal => {
        this.buyNowModal = buyNowModal;
        this.initBuyNowModal();
      });
    }
  }
  
  initGetStartedModal() {
    const { title, sku } = this.getStartedModal.dataset;

    this.getStartedModal.addEventListener('click', function(e) {
      e.preventDefault();
      const modal = new bootstrap.Modal(document.getElementById('getStartedModal'), {
        animation: false
      });

      const modalTitle = document.querySelector('#getStartedModal .title');
      if (modalTitle) {
        modalTitle.textContent = 'Ready to get started with ' + title + '?';
      }
      if (sku) {
        const formSku = document.querySelector('#get-started-form #sku');
        if (formSku) {
          formSku.value = sku;
        }
      }

      modal.show();
    });
    
  }
  
  initBuyNowModal() {
    const { title, sku, icon, leadsource } = this.buyNowModal.dataset;

    this.buyNowModal.addEventListener('click', function(e) {
      e.preventDefault();
      const modal = new bootstrap.Modal(document.getElementById('buyNowModal'), {
        animation: false
      });

      const buyNowModalTitle = document.querySelector('#buyNowModal .title');
      if (buyNowModalTitle) {
        buyNowModalTitle.textContent = 'Ready to get started with ' + title + '?';
      }
      if (sku) {
        const buyNowFormSku = document.querySelector('#buy-now-form #sku');
        if (buyNowFormSku) {
          buyNowFormSku.value = sku;
        }
      }
      if (leadsource) {
        const buyNowFormLeadSource = document.querySelector('#buy-now-form #lead_source');
        if (buyNowFormLeadSource) {
          buyNowFormLeadSource.value = leadsource;
        }
      }
      if (icon) {
        const buyNowFormImg = document.querySelector('#buyNowModal img');
        console.log(buyNowFormImg);
        if (buyNowFormImg) {
          buyNowFormImg.src = icon;
        }
      }

      modal.show();
    });
    
  }
}