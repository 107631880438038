export default class Avatar {

  constructor() {
  }

  /**
  * Translate string to hex color.
  * @param {string} str
  * @return {string|boolean}
  */
  initialsToColor(str) {
    str = String(str).toLowerCase();
    const a = str.charCodeAt(0);
    const b = str.charCodeAt(1) || a;
    let hash = (a*b*(a+b)).toString(16).substring(0, 6).split('').reverse().join('');
    if (hash.length === 5) {
      const color = ('#'+hash+'0');
      return color;
    }
    if (hash.length < 5) {
      return false;
    }
    return '#'+hash;
  }
}