export default class CareersValidation {
  constructor() {
    const careersForm =  document.getElementById('careers-form');
    if (careersForm) {
      careersForm.classList.add('needs-validation');
      this.careersFormValidation();
    }
  }
  careersFormValidation() {
    jQuery.validator.addMethod('email_rule', function(value, element) {
      if (/^([a-zA-Z0-9_\-\.]+)\+?([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(value)) {
        return true;
      };
    });
    jQuery.validator.addMethod('phone_rule', function(value, element) {
      if (/^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/.test(value)) {
        return true;
      };
    }, 'Please enter a valid phone number without dashes or periods.'); 
    $('#careers-form').validate({
      rules: {
        'formfields[firstName]': {
          required: true,
        },
        'formfields[lastName]': {
          required: true,
        },
        'formfields[email]': {
          required: true,
          email_rule: true
        },
        'formfields[address]': {
          required: true,
        },
        'formfields[phoneNumber]': {
          required: true,
          phone_rule: true
        },
        'formfields[resume]': {
          required: true,
        }
      },
      messages: {
        'formfields[firstName]': "Please type your first name.",
        'formfields[lastName]': "Please type your last name.",
        'formfields[email]': "Please enter a valid email address.",
        'formfields[address]': "Please enter a valid address.",
        'formfields[phoneNumber]': "Please enter a valid phone number",
        'formfields[resume]': "Please upload your resume",
      }
    });
  }
}