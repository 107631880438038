export default class Scroll {
  
  constructor() {
    const scrollAuthor = $('.author');
    if (scrollAuthor) {
      this.initScrollUntil();
    }
  }
  
  initScrollUntil() {
    
    function scrollUntil(ElementToScroll, ElementToStopOn) {
      var stickyPoint = $(ElementToScroll).offset().top;
      
      var elementStopPoint = $(ElementToStopOn).offset().top - $(ElementToScroll).outerHeight() - 200;
      window.onscroll = function () {
        fixElement()
      };
      
      function fixElement() {
        $(ElementToScroll).addClass('show-mobile');
        if (window.pageYOffset > stickyPoint && window.pageYOffset < elementStopPoint) {
          $(ElementToScroll).addClass('element-sticky');
          $(ElementToScroll).addClass('show');
        } else if(window.pageYOffset > elementStopPoint) {
          $(ElementToScroll).addClass('fade');
          $(ElementToScroll).removeClass('show');
          
        } else {
          $(ElementToScroll).addClass('show');
          $(ElementToScroll).removeClass('element-sticky');
        }
      }
    }
  }
}