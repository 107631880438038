export default class Slider {
  
  constructor() {
    const headerSlider = document.querySelector('.header-slider'),
    boxesSlider = document.querySelector('.boxes-slider'),
    storeSlider = document.querySelector('.store-slider'),
    storeSliderLg = document.querySelector('.store-slider-lg'),
    marketplaceSlider = document.querySelector('.home-carousel'),
    productsSlider = document.querySelector('.products-slider'),
    careersSlider = document.querySelector('.careers-slider');
    
    if (headerSlider) {
      this.initHeaderSlider();
    }
    if (boxesSlider) {
      this.initBoxesSlider();
    }
    if (storeSlider) {
      this.initStoreSlider();
    }
    if (storeSliderLg) {
      this.initStoreSliderLg();
    }
    if (marketplaceSlider) { // replace with productsSlider
      this.initMarketplaceSlider();
    }
    if (productsSlider) {
      this.initProductsSlider();
    }
    if (careersSlider) {
      this.initCareersSlider();
    }
  }
  
  initHeaderSlider() {
    $('.header-slider').slick({
      arrows: false,
      dots: true,
      fade: true,
      autoplay: true,
      autoplaySpeed: 5000,
      appendDots: '#dots-container'
    });
  }
  initBoxesSlider() {
    $('.boxes-slider').slick({
      arrows: false,
      autoplay: 0,
      slidesToShow: 6,
      responsive: [
        {
          breakpoint: 1399,
          settings: {
            slidesToShow: 5
          }
        }, {
          breakpoint: 991,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: 767,
          settings: {
            slidesToShow: 3
          }
        }, {
          breakpoint: 575,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });
    $('.boxes-slider div').each(function() {
      $(this).attr('tabindex', '-1');
    });
    $('.boxes-slider-prev').each(function() {
      $(this).on('click', function() {
        $('.boxes-slider').slick('slickPrev');
      });
    });
    $('.boxes-slider-next').each(function() {
      $(this).on('click', function() {
        $('.boxes-slider').slick('slickNext');
      });
    });
  }
  initStoreSlider() {
    if (window.innerWidth <= '991') {
      $('.store-slider').slick({
        arrows: false,
        autoplay: 0,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2
            }
          }, {
            breakpoint: 545,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      });
      $('.store-slider div').each(function() {
        $(this).attr('tabindex', '-1');
      });
      $('.store-slider-prev').each(function() {
        $(this).on('click', function() {
          $('.store-slider').slick('slickPrev');
        });
      });
      $('.store-slider-next').each(function() {
        $(this).on('click', function() {
          $('.store-slider').slick('slickNext');
        });
      });
    }
  }
  initStoreSliderLg() {
    if (window.innerWidth <= '1200') {
      $('.store-slider-lg').slick({
        arrows: false,
        autoplay: 0,
        slidesToShow: 4,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3
            }
          }, {
            breakpoint: 767,
            settings: {
              slidesToShow: 2
            }
          }, {
            breakpoint: 545,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      });
      $('.store-slider div').each(function() {
        $(this).attr('tabindex', '-1');
      });
      $('.store-slider-prev').each(function() {
        $(this).on('click', function() {
          $('.store-slider').slick('slickPrev');
        });
      });
      $('.store-slider-next').each(function() {
        $(this).on('click', function() {
          $('.store-slider').slick('slickNext');
        });
      });
    }
  }
  initMarketplaceSlider() {
    $('.home-carousel').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: 0,
      arrow: true,
      dots: true,
    });
  }
  initProductsSlider() {
    $('.products-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: 0,
      arrow: true,
      dots: true,
    });
  }
  initCareersSlider() {
    $('.careers-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: 0,
      arrow: true,
      dots: true,
      responsive: [{
        breakpoint: 600,
        settings: {
          dots: false,
        }
      }]
    });
  }
}