export default class Tracking {
  
  constructor() {
    //GitHub and JSFiddle Tracking
    if ($('a[href*="github.com"]')) {
      this.initGithubTracking();
    }
    if ($('a[href*="jsfiddle.net"]')) {
      this.initJsfiddleTracking();
    }
    window.addEventListener('load', function() {
      const adroll = document.getElementById('adroll_slg');

      if (adroll) {
        adroll.setAttribute('title', 'adroll');
        adroll.style.position = 'absolute';
        adroll.style.height = 0;
      }
    });
  }
  initGithubTracking() {
    $('a[href*="github.com"]').click(function () {
      var url = $(this).attr("href");
      var trackerName = ga.getAll()[0].get('name');
      ga(trackerName + '.send', 'event', 'github', 'click', url, {
        'transport': 'beacon',
        'hitCallback': function () {
          return false;
        }
      });
    });
  }
  initJsfiddleTracking() {
    $('a[href*="jsfiddle.net"]').click(function () {
      var url = $(this).attr("href");
      var trackerName = ga.getAll()[0].get('name');
      ga(trackerName + '.send', 'event', 'jsfiddle', 'click', url, {
        'transport': 'beacon',
        'hitCallback': function () {
          return false;
        }
      });
    });
  }
}