import colorInitials from './avatar';

export default class Login {
 
  constructor() {
    const userAvatarContainer = document.getElementById('user-avatar-container'),
    signInButton = document.getElementById('sign-in-group');

    if (userAvatarContainer && signInButton) {
      this.loadUserLoggedIn();
    }
  }

  loadUserLoggedIn() {
    const userAvatarContainer = document.getElementById('user-avatar-container'),
    signInButton = document.getElementById('sign-in-group');
    const self = this;
    $.ajax({
      url: '/session/is-authenticated',
      type: 'get',
      dataType: 'json',
      success: function(response) {
        if (response.success) {
          var cloud_user = response.user;
          if (userAvatarContainer && signInButton) {
            self.renderUserAvatar(cloud_user);
          }
        }
        _cio.identify({
          id: cloud_user.id,   // Required to identify a person.
      
          // Example attributes (you can name attributes anything you want)
          email: cloud_user.email,
          first_name: cloud_user.first_name,
          last_name: cloud_user.last_name,   
        });
      },
      error: function (err) {
        if (userAvatarContainer && signInButton) {
          self.renderUserAvatar(cloud_user);
        }
      }
    });
  }
  
  renderUserAvatar( user ) {
    const userAvatarContainer = document.getElementById('user-avatar-container'),
    signInButton = document.getElementById('sign-in-group');

    setTimeout(function() {
      signInButton.classList.add('d-none');
    }, 0.5);

    if ( !user ) {
      userAvatarContainer.classList.add('d-none');
      signInButton.classList.remove('d-none');
      return;
    }
    
    if (user.last_name) {
      var avatarTitle = user.first_name ? user.first_name + ' ' + user.last_name : user.name;
      var avatarInitials = user.last_name.charAt(0).toUpperCase() ? user.first_name.charAt(0).toUpperCase() + user.last_name.charAt(0).toUpperCase() : user.first_name.charAt(0).toUpperCase();
    } else {
      var avatarTitle = user.first_name ? user.first_name : user.name;
      var avatarInitials = avatarTitle.charAt(0).toUpperCase();
    }
  
    const userInitials = new colorInitials(),
    isApprovedUserOrAdmin = user.is_approved || user.system_roles.includes('ROLE_USER_ADMIN');
    if (isApprovedUserOrAdmin) {
      var profilePath = '/u/'+ user.name +'/profile';
    }

    var avatarIcon = user.avatar_url ? `<div><div class="rounded-circle w-xs-40p h-xs-40p" style="background: url(${user.avatar_url}) center center no-repeat; background-size: cover;">&nbsp;</div></div>` : `<div><div class="color-avatar h-xs-40p w-xs-40p text-white rounded-circle d-flex align-items-center justify-content-center" data-name="${avatarInitials}" style="background-color: ${userInitials.initialsToColor(avatarInitials)};">${avatarInitials}</div></div>`;
    
    const userDropdown = `
      <a href="#" aria-label="User Menu" id="user-nav-dropdown" data-bs-toggle="dropdown" aria-expanded="false" class="dropdown-toggle text-vulcan">
        <div class="d-flex align-items-center mx-sm-2 p-2">
          ${avatarIcon}
          <div class="ps-3">
            <p class="mb-0 fw-600 fs-15p">${avatarTitle}</p>
          </div>
        </div>
      </a>
      <div class="dropdown-menu bg-white position-absolute w-xs-220p p-0 rounded-0" aria-labelledby="user-nav-dropdown">
        <ul class="list-unstyled mb-0">
          <li id="dashboard" class="px-3 py-2 border-bottom border-white-smoke">
            <a href="/dashboard" class="d-block py-1 text-vulcan fw-600 fs-base-p" aria-label="Dashboard Menu"><span class="fas fa-laptop-code pe-2 w-xs-40p"></span>Dashboard</a>
          </li>
          <li id="profile" class="px-3 py-2 border-bottom border-white-smoke">
            <a href="${profilePath}" class="d-block py-1 text-vulcan fw-600 fs-base-p" aria-label="Profile Menu"><span class="fas fa-user-astronaut pe-2 w-xs-40p"></span>Profile</a>
          </li>
          <li id="logout" class="px-3 py-2">
            <a href="/logout" class="d-block py-1 text-vulcan fw-600 fs-base-p" aria-label="Logout Menu"><span class="fas fa-sign-out-alt pe-2 w-xs-40p"></span>Logout</a>
          </li>
        </ul>
      </div>`;
  
    userAvatarContainer.innerHTML = userDropdown;
    userAvatarContainer.classList.remove('d-none');

    if (!userAvatarContainer.classList.contains('d-none')) {
      this.renderLoggedUserToWallet( user );
    }
  }
  
  renderLoggedUserToWallet( user ) {
    const signIn = document.getElementById('sign-in');

    if (user) {
      if (signIn) {
        signIn.classList.add('d-none');
      }
    }
  }
}