import baguetteBox from 'baguettebox.js';

export default class Utils {
  constructor() {
    const postIntro = $('.post_intro'),
    postIntroShort = $('.post-intro-short'),
    baguette = $('.bag-gallery');

    this.setDataBackgrounds();
    this.setDataURLs();

    if (postIntro || postIntroShort) {
      this.setPostIntro();
    }

    if (baguette) {
      baguetteBox.run('.bag-gallery');
    }
  }

  setDataBackgrounds() {
    if ($('[data-background]')) {
      $('[data-background]').each(function() {
        var attr, that;
        that = $(this);
        attr = that.attr('data-background');
        that.css('background-image', 'url(' + attr + ')');
      });
    }
  }
  setDataURLs() {
    if ($('[data-url-external]')) {
      $('[data-url-external]').each(function() {
        var url, that;
        that = $(this);
        url = that.attr('data-url-external');
        that.on('click', function() {
          window.open(url, '_blank');
        });
      });
    }
    if ($('[data-url]')) {
      $('[data-url]').each(function() {
        var url, that;
        that = $(this);
        url = that.attr('data-url');
        that.on('click', function() {
          window.location = url;
        });
      });
    }
  }
  setPostIntro() {
    const postIntro = $('.post_intro'),
    postIntroShort = $('.post-intro-short');

    $(postIntro).each(function() {
      $(this).text(cut(200));
    });

    $(postIntroShort).each(function() {
      $(this).text(cut(150));
    });

    function cut(n) {
      return function truncate(i, str) {
        return (str.length > n) ? str.substr(0, n-1) + '...' : str;
      };
    }
  }
}