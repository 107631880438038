export default class CustomerIO {

  constructor() {
    const cioBtns = document.querySelectorAll('.cio-track'),
    careersForm = document.querySelector('.careers-form form');

    if (cioBtns.length) {
      cioBtns.forEach(cioBtn => {
        this.cioBtn = cioBtn;
        this.cioTrack();
      });
    }
  
    if (careersForm) {
      this.careersForm = careersForm;
      this.careersDataPosition();
    }
  }

  cioTrack() {

    this.cioBtn.addEventListener('click', (event) => {
      const name = event.target.getAttribute('data-name'),
      leadsource = event.target.getAttribute('data-leadsource'),
      sku = event.target.getAttribute('data-sku');

      if (typeof _cio !== 'undefined' && _cio.track) {
        if (sku) {
          if (name) {
            _cio.track('button_click', { segment: leadsource, type: 'button', leadsource: name, sku: sku });
          } else {
            _cio.track('button_click', { segment: leadsource, type: 'button', sku: sku });
          }
        } else {
          _cio.track('button_click', { segment: name, type: 'button', leadsource: leadsource });
        }
      } else {
        console.error('_cio is not defined');
      }
    });
  }

  careersDataPosition() {
    const sku = document.getElementById('sku'),
    pathname = window.location.pathname;

    if (pathname.includes('/careers')) {
      // Remove /careers
      let newPathname = pathname.replace('/careers', '');
      // Remove .stml extension
      newPathname = newPathname.replace('.stml', '');

      newPathname = newPathname.replace('/', '');

      sku.value = newPathname;
    }
  }
}
