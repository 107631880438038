export default class Form {
  
  constructor() {
    const heroForm = document.querySelector('.main-hero .hero-form form'),
    ctaForm = document.querySelector('.cta-form form'),
    forms = document.querySelectorAll('form'),
    resourcesForms = document.querySelector('.resources-right-form');
    
    if (heroForm) {
      this.heroForm = heroForm;
      this.addClassToHeroForm();
    }
    if (ctaForm) {
      this.ctaForm = ctaForm;
      this.addClassToCTAForm();
    }
    if (forms.length) {
      forms.forEach(form => {
        this.form = form;
        this.removeRole();
      });
    }
    if (resourcesForms) {
      this.resourcesForms = resourcesForms;
      this.resourcesForm();
    }
  }

  addClassToHeroForm() {
    this.heroForm.classList.add('row');
  }

  addClassToCTAForm() {
    this.ctaForm.classList.add('row', 'row-cols-1', 'row-cols-lg-2');
  }
  removeRole() {
    this.form.removeAttribute('role');
  }
  resourcesForm() {
    const formTitle = this.resourcesForms.dataset.title,
    formButton = this.resourcesForms.dataset.button,
    fileName = this.resourcesForms.dataset.name,
    fileParentId = this.resourcesForms.dataset.id,
    title = this.resourcesForms.querySelector('h2');

    if (formTitle != '') {
      title.textContent = formTitle;
    } else {
      title.textContent = 'Get your free copy';
    }
    if (formButton != '') {
      document.getElementById('resource-btn').value = formButton;
    } else {
      document.getElementById('resource-btn').value = 'Download Now';
    }
    if (fileName != '') {
      document.getElementById('asset_file').value = fileName;
    }
    if (fileParentId != '') {
      document.getElementById('asset_file_id').value = fileParentId;
    }
  }
}